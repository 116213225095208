// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import InvoiceBilling from '../../blocks/InvoiceBilling/src/InvoiceBilling.web'
import Trending from '../../blocks/Trending/src/Trending.web'
import TrendingDetails from '../../blocks/Trending/src/TrendingProductDetails.web'
import VideoPlay from '../../blocks/PhotoLibrary3/src/VideoPlay.web'
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement.web'
import SavedCards  from '../../blocks/Payments/src/SavedCards.web'
import Payments  from '../../blocks/Payments/src/Payments.web'
import SearchHistory from '../../blocks/SearchHistory/src/SearchHistory.web'
import AffiliateUrl from '../../blocks/AffiliateUrl/src/AffiliateUrl.web'
import Maps from '../../blocks/maps/src/Maps.web'
import AddNewLocation from '../../blocks/maps/src/AddNewLocation.web'

const routeMap = {

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling',
    exact: true
  },
  Trending: {
    component: Trending,
    path: '/Trending',
    exact: true
  },
  TrendingDetails: {
    component: TrendingDetails,
    path: '/TrendingDetails',
    exact: true
  },
  VideoPlay: {
    component: VideoPlay,
    path: '/VideoPlay',
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement',
     exact: true
  },
  SavedCards: {
    component: SavedCards,
    path: '/SavedCards',
        exact: true
  }, 
   Payments: {
    component: Payments,
    path: '/Payments',
        exact: true
  },
  SearchHistory: {
    component: SearchHistory,
    path: '/SearchHistory',
      exact: true
      },
  AffiliateUrl: {
    component: AffiliateUrl,
    path: '/AffiliateUrl',
    exact: true
  },
  Maps: {
    component: Maps,
    path: '/Maps',
    exact: true
  },
  AddNewLocation: {
    component: AddNewLocation,
    path: '/AddNewLocation',
    exact: true
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
